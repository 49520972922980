import React, { useState, useEffect, useContext } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

import EmergencyList from '../templates/emergencyList';
import DashboardCards from '../templates/dashboardCards';

import NoticeList from '../templates/noticeList';
import SpinnerTnrd from '../components/spinner';

import {currentEmergenciesContext} from '../components/root'

document.title = 'Dashboard - TNRD EOC'

function RenderCurrentButtons(emergencies){
  var em = useContext(currentEmergenciesContext);
  if (em.length > 0) return (<><Button href="/current" className="me-2 btn-tnrd">View all Current Emergencies</Button><Button className="btn-tnrd" href="/notices/current" >View all Current Notices</Button></>)
  return (<><Button className="btn-tnrd" href="/notices" >View all Emergency Notices</Button></>)
}

function RenderNumberOfEmergencies(){
  var em = useContext(currentEmergenciesContext);
  if (em.length === 0){
    return(<h3>There are no current emergency events</h3>);
  }
  return (<h3>Active Emergency Events: {em.length}</h3>)
}

export function Dashboard() {

  const [notices, setNotices] = useState();

  var em = useContext(currentEmergenciesContext);
  
  useEffect(() => {
    fetch('https://api.tnrd.ca/eoc/notices/?limit=10')
    .then(response => response.json())
    .then(data => {
      setNotices(data);
  })
    .catch(error => console.error(error));

}, []);

  if(em && notices){
  return (
    <>
    <Container>
      <Row className="jumbotron justify-content-center">
        <Col sm="12" md="10">
        <div className="text-center">
          <h1 className="jumbotron-heading">Emergency Operations Centre</h1>
          { /*}<p className="lead text-muted">When an <strong>Evacuation Order</strong> is issued, you must leave the area immediately. Follow the directions of local emergency officials and evacuate using the route(s) they’ve identified. Do not return home until you’ve been advised that the Evacuation Order has been rescinded.</p>

<p className="lead text-muted">When an <strong>Evacuation Alert</strong> is issued, this is the time to get prepared to leave your home on short notice. Get your grab-and-go bags ready (which should include several days of clothing, toiletries and medications), your emergency plan, copies of important documents and important mementos.</p>

<p  className="lead text-muted">Learn more about key information and differences about an <a href="https://www.tnrd.ca/services/emergency-services/emergency-preparedness/evacuation-alert-vs-evacuation-order/" target="_blank">Evacuation Order vs. Evacuation Alert.</a></p>
  {*/}
            <DashboardCards/>
            <RenderNumberOfEmergencies/>
          <p>
            <RenderCurrentButtons/>
            
          </p>
      </div>
          
        </Col>
    </Row>
    <Row>
      
    </Row>
    </Container>
    <div className="bg-light info-cards-wrapper">
    <Container>
    <Row className>
      <Col sm={12} lg={6} className="mb-2">
      <Card>
        <Card.Header>Current Emergency Events</Card.Header>
        <Card.Body>
        <ListGroup className="list-group-flush">
        <EmergencyList emergencies={em}/>
        </ListGroup>
        </Card.Body>
      </Card>

      </Col>
      <Col sm={12} lg={6}>
      <Card>
      <Card.Header>All Emergency Notices <a class="float-end" href="/notices"><small>View All</small></a></Card.Header>
        <Card.Body>
          <ListGroup className="list-group-flush">
            <NoticeList notices={notices} status={true}/>
          </ListGroup>
        </Card.Body>
      </Card>

      </Col>
    </Row>
  </Container>
  </div>
  </>
  );
  } else {
    return (<Container><SpinnerTnrd/></Container>);
  }
}