import conf from  '../conf.js';

import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import parse from 'html-react-parser';
import SpinnerTnrd from '../components/spinner';


function view_emergency(e){
  e.slug = e.slug?e.slug:e.page_name?e.page_name:'';
  window.open(window.location.origin+'/eoc/'+e.slug+'-'+e.id, '_blank').focus();
}

function notices(notices,archived){
  if(archived){
    if(notices.length > 0){
      var not = notices.filter((notice) => notice.notice_status == 'Archived');
    } else {
      var not = false;
    }
    return(
      <>Notices:{' '}
      <strong>{not?not.length:'0'}</strong></>
    )
  } else {
    if(notices.length > 0){
    var not = notices.filter((notice) => notice.notice_status == 'Current');
  } else {
    var not = false;
  }
    return(
      <>Current Notices:{' '}
      <strong>{not?not.length:'0'}</strong></>
    )
  }
  
}

function Notices(props){

  var n = props.emergencynotices;
  var a = props.archived
  return (
    <div>
    {notices(n,a)}
    </div>
  )
}

function EmergencyList(props){
    var emergencies = props.emergencies;
    var shownotices = props.shownotices?props.shownotices:1;
    var archived = props.archived?props.archived:false;
    if(emergencies){
    if(emergencies.length > 0){
    return emergencies.map(function(emergency, i){
      if(emergency.notices){
        var latest_notice = new Date(emergency.latest_notice[0].post_date);
      } else {
        var latest_notice = false;
      }
        return (
          <ListGroup.Item key={i} onClick={() => {view_emergency(emergency)}}
          as="li"
          className="list_emergency d-flex justify-content-between align-items-start"
        >
          <div className="ms-2 me-auto">
            <div className="fw-bold">{parse(emergency.title.rendered)}</div>
            Last Update: {latest_notice?latest_notice.toLocaleString([],conf.dateoptions):'No Updates'}
          </div>
          <Notices emergencynotices={emergency.notices} archived={archived}/>
        </ListGroup.Item>
        )
      });
    } else {
      return(
        <><p>No Current Emergencies</p></>
      );
    }
  } else{
    return (<SpinnerTnrd/>)
  }
}

export default EmergencyList;