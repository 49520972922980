import conf from  '../conf.js';

import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import parse from 'html-react-parser';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import ListGroup from 'react-bootstrap/ListGroup';

import NoticeList from '../templates/noticeList';
import DocumentList from '../templates/documentList';

function status_type(status){
    var cla = "success";
    var html = 'Current'
    if(status == '238' || status == 'Archived'){
       cla = 'warning'; 
       html = 'Archived'
    }
    return <Badge className="status" bg={cla}>{html}</Badge>;
}

function notice_type(types){
    if (!Array.isArray(types)){
        types = [types]
    }
    return types.map(function(type, i){
        var cla = "success";
        switch (type) {
            case 'Evacuation Alert':
                cla = "warning";
                break;
            case 'Evacuation Order':
                cla = 'danger';
                break;
            case 'Information':
                cla = 'info';
        };
        return <Badge className="type" bg={cla}>{type}</Badge>;
    })
}

function RelatedNoticesCard(props){
    var notices = props.notices;
    var emergency = props.emergency;

    return (
        <Card className="mt-2">
            <Card.Header>All Related Notices | <small>{notices.length} Total notices related to <span className="return_to">{emergency.title.rendered}</span></small></Card.Header>
            <Card.Body>
                <ListGroup className="list-group-flush">
                    <NoticeList notices={notices} status={true}/>
                </ListGroup>
            </Card.Body>
        </Card>
    )
}

function CurrentNoticesCard(props){
    var notices = props.notices.reverse();
    var emergency = props.emergency;

    var curnotices = notices.filter((not) => not.notice_status == 'Current');

    if(curnotices.length > 0){

    return (
        <Card className="mt-2">
            <Card.Header>Current Notices | <small>{curnotices.length} Current notices related to <span className="return_to">{emergency.title.rendered}</span></small></Card.Header>
            <Card.Body>
                <ListGroup className="list-group-flush">
                    <NoticeList notices={curnotices} status={true}/>
                </ListGroup>
            </Card.Body>
        </Card>
    )
    }

    return (<></>)

}

function NoticeDetail(props){
    const [latestNotice, setLatestNotice] = useState();

    var emergency = props.emergency;

    useEffect(() => {
        if(emergency.notices){
           
        fetch('https://api.tnrd.ca/eoc/post/'+emergency.latest_notice[0].ID)
        .then(response => response.json())
        .then(data => {
            setLatestNotice(data);
      })
        .catch(error => console.error(error));
    }
    }, []);

    function status_information(){
        if(emergency.status_information_link != '' && emergency.status_information_title != ''){
            return (<p><a target="_blank" href={emergency.status_information_link}>{emergency.status_information_title}</a></p>)
        }
        else {
            return(<></>)
        }
    }

    function effected_areas(){
        if(emergency.electoral_areas_effected && emergency.electoral_areas_effected.length > 0){
        return(<p>Electoral Areas: <strong>{emergency.electoral_areas_effected.join(', ')}</strong></p>)
        }

        return (<></>)
    }

    if(!emergency.notices){
        
        return(
            <>
            <h1 className="display-6">{emergency.title.rendered}</h1>
        <p>Last Updated: <strong>No Updates</strong> | Status: {status_type(emergency.emergency_status[0])} | Notices: <strong>0</strong></p>
        
            </>
        )
    }

    if(latestNotice){
        var last_updated = new Date(emergency.latest_notice[0].post_date);
    return( 
        <>
        <h1 className="display-6">{emergency.title.rendered}</h1>
        <p>Last Updated: <strong>{last_updated.toLocaleString([], conf.dateoptions)}</strong> | Status: {status_type(emergency.emergency_status[0])} | Notices: <strong>{emergency.notices.length}</strong> </p>
        {effected_areas()}
        {status_information()}
        { /*}
        <Card>
            
        <Card.Header>Latest Notice</Card.Header>
            <Card.Body>
                <h4>{parse(latestNotice.title.rendered)}</h4>
                <div>
                    <p>Posted: <strong>{last_updated.toLocaleString([],conf.dateoptions)}</strong> | Type: {notice_type(latestNotice.notice_type)} | Status: {status_type(latestNotice.notice_status[0])} </p>
                </div>
                <div class="emergency_content">
                    {parse(latestNotice.content.rendered)}
                </div>
                <Button className="btn-tnrd" target="_blank" href="https://www.tnrd.ca/emergencymap" variant="primary"><i class="bi bi-box-arrow-up-left me-2"></i>View Emergency Map</Button>
                
                <div className="document_wrapper">
                    <h4>Documents</h4>
                    <CardGroup>
                        <DocumentList documents={latestNotice._documents} />
                    </CardGroup>
                </div>
            </Card.Body>
        </Card>
    {*/}
        <CurrentNoticesCard notices={emergency.notices} emergency={emergency}/>

        <RelatedNoticesCard notices={emergency.notices} emergency={emergency}/>
        </>
    )
    } else {
        return (<></>)
    }
}

export default NoticeDetail;