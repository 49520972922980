
function ContactInfo(props){
 return(
    <p>
        Phone: <a href="tel:2503777188">250-377-7188</a><br/>
        Toll Free: <a href="tel:18663777188">1-866-377-7188</a><br/>
        <a href="mailto:emergency@tnrd.ca">emergency@tnrd.ca</a>
    </p>
 )
}

export default ContactInfo;
