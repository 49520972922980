import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Wrapper from '../components/wrapper';

export function NotFound(props) {

    document.title = '404 - TNRD EOC'

    useEffect(() => {
        props.crumbs([
        ['404','/',true]
        ]
          )
        }, []);

    return (
        <Wrapper page="Not Found">
            <div>
                <h1>Oops, looks like something went wrong</h1>
                <p>Please try again</p>
            </div>
        </Wrapper>
    );
  }