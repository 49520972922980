import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';


function MailingList() {  

    return (
        <div className="mailingList pt-5 pb-5">
            <Container className="text-center">
                <Row className="justify-content-md-center">
                    <Col xs={12} md={5}>
                    <h3>Sign-up to receive TNRD Emergency Notices</h3>
                    <Form action="https://app.cyberimpact.com/optin" method="post" target="_blank">
                    <Row className="justify-content-md-center">
                            <Col xs={12} md={8}>
                            <InputGroup className="mb-3" controlid="ci_email">
                            <Form.Control type="email" name="ci_email" placeholder="Email" />
                            <Button className="btn-tnrd" type="submit">
                                Sign Up
                            </Button>
                            </InputGroup>
                            <input type="hidden" id="ci_groups" name="ci_groups" value="2047" />
                            <input type="hidden" id="ci_account" name="ci_account" value="f115970c-8aa7-4093-394a-3ccc8e515d9e" />
                            <input type="hidden" id="ci_language" name="ci_language" value="en_ca" />
                            <input type="hidden" id="ci_sent_url" name="ci_sent_url" value="" />
                            <input type="hidden" id="ci_error_url" name="ci_error_url" value="" />
                            <input type="hidden" id="ci_confirm_url" name="ci_confirm_url" value="" />
                           
                            </Col>
                            
                    </Row>
                    </Form>
                    <p><strong>Please note</strong> to receive emergency alerts directly to your device you should register with <a href="https://www.tnrd.ca/services/emergency-services/emergency-preparedness/emergency-alerts/" target="_blank">Voyent Alert</a></p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default MailingList;