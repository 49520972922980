import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container';

function Update(props){
  return props.crumbs.map(function(crumb,i){
    return (
      <><Breadcrumb.Item key={i} active={crumb[2]?'true':'false'} href={crumb[1]}>{crumb[0]}</Breadcrumb.Item></>
    )
  })
}

function Breadcrumbs(props) {

  return (
      <div className="breadcrumb_wrapper">
        <Container>
        <Breadcrumb>
          <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
          <Update crumbs={props.crumbs}/>
        </Breadcrumb>
      
      </Container>
      </div>
  );
}

export default Breadcrumbs;