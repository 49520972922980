import conf from  '../conf.js';

import React, { useState, useEffect, useContext } from "react";
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import parse from 'html-react-parser';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Button from 'react-bootstrap/Button';

import {currentEmergenciesContext} from '../components/root'

function status_type(status){
    var cla = "success";
    var html = 'Current'
    if(status == '238'){
       cla = 'warning'; 
       html = 'Archived'
    }
    return <Badge className="type" bg={cla}>{html}</Badge>;
}

function Cards(){
    
    var emergencies = useContext(currentEmergenciesContext);
    return emergencies.map(function(e, i){
        if(e.show_card == "1"){
        if(e.notices.length > 0){
            var updated = new Date(e.latest_notice[0].post_date);
        } else {
            var updated = false;
        }
        return (
            <Card className="text-center">
                <Card.Body>
                <Card.Title>{e.title.rendered}</Card.Title>
                <Card.Text>
                    <p>Status: {status_type(e.emergency_status[0])} | Notices: <strong>{e.notices.length}</strong></p>
                    <Button className="btn-tnrd" href={'/eoc/'+e.slug+'-'+e.id} target="_blank" variant="primary">View Updates</Button>
                </Card.Text>
                </Card.Body>
                <Card.Footer>
                    <small className="text-muted">Last Updated: {updated?updated.toLocaleString([],conf.dateoptions):'No Updates'}</small>
                </Card.Footer>
            </Card>
        );
        } else {
            return (<></>)
        }
    });
    
}

function DashboardCards(){
    var emergencies = useContext(currentEmergenciesContext);
    if(emergencies.length > 0){
        return (
            <CardGroup className="mb-2">
            <Cards/>
            </CardGroup>
        )
    } else {    
        return (
            <></>
        )
    }
}

export default DashboardCards;
