import ContactInfo from '../templates/contactInfo';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MailingList from './mailinglist';

function Footer() {
  return (
    <>
<MailingList/>
<div className="footer-wrapper">
<Container>
  <footer className="py-5">
    <Row>
      <Col xs={12} md={3} className="mb-2">
        <h5>Quick Links</h5>
        <ul className="nav flex-column">
          <li className="nav-item mb-2"><a href="https://www.tnrd.ca/services/emergency-services/emergency-resources/" target="_blank" className="nav-link p-0 text-muted">Emergency Resources</a></li>
          <li className="nav-item mb-2"><a href="https://www.tnrd.ca/services/emergency-services/emergency-preparedness/emergency-alerts/" target="_blank" className="nav-link p-0 text-muted">Register for Voyent Alert</a></li>
          <li className="nav-item mb-2"><a href="https://www.tnrd.ca/services/emergency-services/emergency-preparedness/evacuation-alert-vs-evacuation-order/" target="_blank" className="nav-link p-0 text-muted">Evacuation Alert vs. Evacuation Order</a></li>
          <li className="nav-item mb-2"><a href="https://www.tnrd.ca/services/emergency-services/emergency-preparedness/firesmart/" target="_blank" className="nav-link p-0 text-muted">FireSmart</a></li>
          <li className="nav-item mb-2"><a href="https://www.tnrd.ca/services/emergency-services/emergency-preparedness/wildfire-preparedness/" target="_blank" className="nav-link p-0 text-muted">Wildfire Preparedness</a></li>
          


          
        </ul>
      </Col>

      <Col xs={12} md={3} className="mb-2">
        <h5>Emergency Operations Centre</h5>
        <ul className="nav flex-column">
          <li className="nav-item mb-2"><a href="/" className="nav-link p-0 text-muted">Dashboard</a></li>
          <li className="nav-item mb-2"><a href="/current" className="nav-link p-0 text-muted">Current Emergencies</a></li>
          <li className="nav-item mb-2"><a href="/archived" className="nav-link p-0 text-muted">Archived Emergencies</a></li>
          <li className="nav-item mb-2"><a href="/notices" className="nav-link p-0 text-muted">All Emergency Notices</a></li>
          <li className="nav-item mb-2"><a target="_blank" rel="noreferrer" href="https://tnrd.maps.arcgis.com/apps/webappviewer/index.html?id=5fb33103276a41c3bc4975dc20eb1219" className="nav-link p-0 text-muted">Interactive Map</a></li>
        </ul>
      </Col>


      <Col xs={12} md={6} className=" contact">
        <h5>Contact</h5>
        <p>TNRD Emergency Operations Centre</p>
        <ContactInfo />
        <div className="social">
        <a href="https://www.facebook.com/tnrd.ca/" target="_blank"><i className="bi bi-facebook"></i></a>
        <a href="https://twitter.com/TNRD" target="_blank"><i className="bi bi-twitter-x"></i></a>
        <a href="https://www.youtube.com/playlist?list=PLn9sMickkNBHUbnhibED0SZruQdFjZHZh" target="_blank"><i className="bi bi-youtube"></i></a>
        </div>
      </Col>
    </Row>

    <Row className="d-flex justify-content-between py-4 my-4 border-top logo">
      <Col xs={12} md={3}><a href="https://www.tnrd.ca" target="_blank" rel="noreferrer"><img alt="logo" src="https://wpcdn.tnrd.ca/wp-content/uploads/2020/09/17134927/TNRD_horizontal_CMYK-1.svg"/></a>
      </Col>

      <Col xs={12} md={6} className=" mini"><p>Thompson Nicola Regional District <br/><small>Emergency Operations Centre</small></p><p><small><a href="https://www.tnrd.ca/privacy-policy/" target="_blank">Privacy Policy</a></small></p>

    </Col>
    </Row>
  </footer>
</Container>
</div>
    </>
  );
}

export default Footer;